import React from 'react';

export enum Theme {
    Dark = "Escuro",
    Light = "Claro",
    Auto = "Automático",
}

import { ReactComponent as DarkLightIcon } from "../assets/icons/dark_light.svg";


type ThemeNames = keyof typeof Theme;

type ThemeIconMap = Record<ThemeNames, React.ReactNode>;


export const THEME_ICONS_MAP: ThemeIconMap = {
    'Dark': 'pi pi-moon',
    'Light': 'pi pi-sun',
    'Auto': <DarkLightIcon />,
};

type ThemeChangeListener = (theme: Theme, icon: React.ReactNode) => void;


const Light_THEME_COLOR = "#ffffff"
const Dark_THEME_COLOR = "hsl(240 7% 10%)"



export class ThemeService {
    private _theme: Theme;
    private _listeners = new Set<ThemeChangeListener>();

    nextMode(){
        let theme: Theme
        switch (this.theme) {
            case Theme.Auto:
                theme = Theme.Dark
                break;
            case Theme.Dark:
                theme = Theme.Light
                break
            default:
                theme = Theme.Auto
        }
        this.theme = theme
        return false
    }

    constructor(){
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            const theme = window.localStorage.getItem('theme')
            if (!theme || theme === 'Auto') {
                document.documentElement.dataset.theme = event.matches ? 'dark' : 'light'
            }
        });

        let theme: Theme = window.localStorage.getItem('theme') as Theme
        switch (theme) {
            case Theme.Dark:
            case Theme.Light:
            case Theme.Auto:
                break
            default:
                theme = Theme.Light
        }

        this._theme = theme
        this.theme = theme
        this.nextMode = this.nextMode.bind(this)
        this.addListener = this.addListener.bind(this)
        this.removeListener = this.removeListener.bind(this)
    }


    addListener(callback: ThemeChangeListener){
        this._listeners.add(callback)
    }

    removeListener(callback: ThemeChangeListener){
        this._listeners.delete(callback)
    }

    get theme(): Theme {
        return this._theme
    }

    set theme(theme: Theme){

        switch (theme) {
            case Theme.Light:
                document.documentElement.dataset.theme = 'light'
                document.documentElement.style.colorScheme = 'light'
                document.querySelectorAll('meta[name="theme-color"]').forEach(el=>{
                    el.setAttribute('content', Light_THEME_COLOR)
                })
                break;
            case Theme.Dark:
                document.querySelectorAll('meta[name="theme-color"]').forEach(el => {
                    el.setAttribute('content', Dark_THEME_COLOR)
                })
                document.documentElement.dataset.theme = 'dark'
                document.documentElement.style.colorScheme = 'dark'
                break;
            case Theme.Auto:
                document.querySelector('meta[name="theme-color"][media="(prefers-color-scheme: light)"')?.setAttribute('content', Light_THEME_COLOR);
                document.querySelector('meta[name="theme-color"][media="(prefers-color-scheme: dark)"')?.setAttribute('content', Dark_THEME_COLOR);
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
                    document.documentElement.dataset.theme = 'dark'
                    document.documentElement.style.colorScheme = 'dark'
                } else {
                    document.documentElement.dataset.theme = 'light'
                    document.documentElement.style.colorScheme = 'light'
                }
                break;
            default:
                this.theme = Theme.Auto
                return
        }

        window.localStorage.setItem('theme', theme)
        this._theme = theme
        this._listeners.forEach(listener=>{
            try{
                listener(this.theme, this.icon)
            } catch(e: any){
                console.error(e)
            }
        })
    }

    get icon(): React.ReactNode {
        const name: ThemeNames = Object.getOwnPropertyNames(Theme).find(
            (t)=>{
                return Theme[t as unknown as ThemeNames] === this.theme
        })! as ThemeNames
        return THEME_ICONS_MAP[name]
    }

}

