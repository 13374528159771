import React from 'react'




export const LoadingPage = () => {

    return <>
        <section className="h-screen bg-gray-200 dark:bg-transparent" id="loading">
            <div className="container h-full px-6 md:py-24 mx-auto flex lg:block">
                <div className="flex w-full self-center flex-wrap items-center justify-center">
                    <div className='w-full flex justify-center'>
                        <div className='logo !w-[100px] !h-[100px] animate-pulse'></div>
                    </div>
                    <div className='w-full flex justify-center'>
                        Carregando dados...
                    </div>
                </div>
            </div>
        </section>
    </>
}
