import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://2ac7173b2d3ff361ef5ce648b76be0ba@sentry.procyon.tec.br/2",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend(event) {
    if (event?.exception?.values?.every(e=>e?.type === 'CanceledError')){
      return null
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 0.01,
  // Session Replay
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0, 
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
