import React from 'react';
import { ThemeService } from '../services/ThemeService';

export const DefaultThemeService = new ThemeService();

export const ThemeContext = React.createContext<ThemeService>(DefaultThemeService);


type ThemeProviderProps = {
    children?: React.ReactNode | undefined
}

export const ThemeProvider = (props: ThemeProviderProps) => {

    return <ThemeContext.Provider value={DefaultThemeService}>
        {props.children}
    </ThemeContext.Provider>
}