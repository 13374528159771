import React from 'react';

import { ThemeProvider } from './providers/ThemeContext';
import 'primeicons/primeicons.css';
import './styles/styles.scss';
import { LoadingPage } from './components/misc/LoadingPage';




const Wrapper = React.lazy(()=>import('./Wrapper'));


const App = React.memo(function App(){
  
  return (
    <ThemeProvider>
      <React.Suspense fallback={<LoadingPage />}>
        <Wrapper />
      </React.Suspense>
    </ThemeProvider>
  );
})

export default App;
